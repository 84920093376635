import { template as template_6755478d62b647abaa17947596eed91b } from "@ember/template-compiler";
const FKText = template_6755478d62b647abaa17947596eed91b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
