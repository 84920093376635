import { template as template_4050b9dddd38445a8eb7dbf968781c43 } from "@ember/template-compiler";
const FKLabel = template_4050b9dddd38445a8eb7dbf968781c43(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
