import { template as template_2271c1e2d92f4b208cae5b48b3c3bedc } from "@ember/template-compiler";
const SidebarSectionMessage = template_2271c1e2d92f4b208cae5b48b3c3bedc(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
